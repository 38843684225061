import React from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";

import Raw from "components/raw";
import SEO from "components/seo";
import { buildReplaceForSinglePageManual } from "utils/html";

export const query = graphql`
    query($language: String!) {
        ...seo
        lang(key: $language) {
            ...footer
            pages: guidePages {
                html
                fields {
                    slug
                    relative
                }
                frontmatter {
                    description
                    title
                }
                media {
                    relativePath
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_noBase64
                            ...GatsbyImageSharpFluidLimitPresentationSize
                        }
                    }
                }
            }
            layout: guide {
                pages
                menu {
                    menuItems {
                        title
                        url
                    }
                }
            }
        }
    }
`;

const SinglePageManual = ({
    data: {
        lang: { footer, pages, layout },
        seo,
    },
    pageContext: { language, translations },
}) => {
    pages.sort(
        (a, b) =>
            layout.pages.indexOf(a.fields.relative) -
            layout.pages.indexOf(b.fields.relative)
    );

    return (
        <Layout menu={layout.menu} footer={footer} translations={translations}>
            <SEO
                title={pages[0].frontmatter.title}
                description={pages[0].frontmatter.description}
                language={language}
                seo={seo}
            />
            <section className="guide section">
                <div className="container mx-auto">
                    {pages.map((page, index) => (
                        <div key={index} id={page.fields.slug}>
                            <Raw
                                html={page.html}
                                replace={buildReplaceForSinglePageManual(
                                    page.fields.slug,
                                    page.media
                                )}
                            />
                        </div>
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default SinglePageManual;
